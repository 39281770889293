var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-Noto-Sans corporate-page"},[_c('v-sheet',{attrs:{"color":"transparent"}},[_c('PageTitle',{attrs:{"items":{
        title: '企業管理',
        subTitle: '一覧',
        tabs: [
          {
            title: 'アクティブ',
            count: _vm.getApprovedCompanies,
            action: _vm.showActiveTables
          },
          {
            title: 'インアクティブ',
            count: _vm.getNotApprovedCompanies,
            action: _vm.showNotActiveTables
          }
        ],
        buttons: [
          {
            title: 'CSVエクスポート',
            icon: null,
            action: this.downloadCsv,
            others: [{ outlined: true }],
            class: 'bg-white text-ff862f'
          },
          {
            title: '新規作成',
            icon: 'mdi-plus-circle',
            action: this.goToCreate,
            others: null,
            class: []
          }
        ]
      }}}),_c('SearchBox',{on:{"search-table":_vm.searchTable}}),_c('v-row',[_c('v-col',{staticClass:"d-flex w-100",attrs:{"cols":"12","md":"12"}},[_c('DataTable',{ref:"pagination",attrs:{"items":_vm.initialLoad ? [] : _vm.getAllCorporate,"headers":_vm.headers,"total-records":_vm.totalRecords,"number-of-pages":_vm.totalPages,"loading":_vm.loading},on:{"update:options":_vm.updateTable,"click:row":function($event){return _vm.$router.push({
              name: 'CorporateEdit',
              params: { id: $event.id }
            })}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))])]}},{key:"item.business_industry",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.business_industry ? item.business_industry.name : ''))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }